var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.displayId)?_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","hide-details":"","outlined":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.state.search),callback:function ($$v) {_vm.$set(_vm.state, "search", $$v)},expression:"state.search"}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"checkbox-color":"red","dense":"","headers":_vm.headers,"hide-default-footer":!!_vm.displayId,"item-key":"id","items":_vm.state.meetings,"loading":_vm.state.loading,"search":_vm.state.search,"show-select":"","single-select":""},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","disabled":!!_vm.displayId,"ripple":false,"value":!!_vm.displayId || isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.teamName",fn:function(ref){
var item = ref.item;
return [(item.teamName)?_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(item.teamName)+" ")]):_c('span',[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)]}},{key:"item.channelName",fn:function(ref){
var item = ref.item;
return [(item.channelName)?_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(item.channelName)+" ")]):_c('span',[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }