























































import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  watch,
} from "@vue/composition-api"
import { MeetingStructureSummary } from "@/models/Meeting"
import { meetingContainer } from "@/containers/MeetingsContainer"

export class TeamMeetingStructure extends MeetingStructureSummary {
  teamName: string
  channelName: string
  constructor(
    input: MeetingStructureSummary,
    teamName: string,
    channelName: string
  ) {
    super(input)
    this.teamName = teamName
    this.channelName = channelName === "General" ? "一般" : channelName
  }
}

interface State {
  loading: boolean
  search: string
  meetings: Array<TeamMeetingStructure>
}

export default defineComponent({
  props: {
    value: MeetingStructureSummary || null,
    priorityIds: {
      type: Array as PropType<Array<string>>,
      default: [] as Array<string>,
    },
    displayId: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { getTeams } = teamsContextContainer.useContainer()
    const { getAllMeetingsAsync } = meetingContainer.useContainer()
    const state = reactive<State>({
      loading: false,
      search: "",
      meetings: [],
    })
    const selected = computed({
      get: () => {
        return props.value ? [props.value] : []
      },
      set: (newValue?: Array<MeetingStructureSummary>) => {
        if (newValue && newValue.length > 0) emit("input", newValue[0])
        else emit("input", null)
      },
    })
    const sortTeamMeeting = (
      a: TeamMeetingStructure,
      b: TeamMeetingStructure
    ) => {
      if (props.priorityIds.includes(a.id) && !props.priorityIds.includes(b.id))
        return -1
      if (!props.priorityIds.includes(a.id) && props.priorityIds.includes(b.id))
        return 1

      if (a.project?.groupId && b.project?.groupId) {
        if (a.project?.groupId > b.project?.groupId) return 1
        if (a.project?.groupId < b.project?.groupId) return -1
      }
      if (a.project?.channelId && b.project?.channelId) {
        if (a.project?.channelId > b.project?.channelId) return 1
        if (a.project?.channelId < b.project?.channelId) return -1
      }
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    }

    const headers = [
      {
        text: "チーム名",
        value: "teamName",
      },
      {
        text: "チャネル名",
        value: "channelName",
      },
      {
        text: "会議体名",
        value: "name",
      },
    ]

    watch(
      () => props.displayId,
      (newId: string) => {
        state.meetings = state.meetings.filter(m => !newId || m.id === newId)
      }
    )

    onMounted(async () => {
      state.loading = true
      const allMYMeetings = await getAllMeetingsAsync()
      state.meetings = (
        allMYMeetings.map(ms => new TeamMeetingStructure(ms, "", "")) || []
      )
        .sort(sortTeamMeeting)
        .filter(m => !props.displayId || m.id === props.displayId)
      state.loading = false
      // チームの情報を取得して当てはめる
      const teams = await getTeams(
        state.meetings
          .filter(tm => !!tm.project?.groupId)
          .map(g => g.project?.groupId || "")
      )
      state.meetings = state.meetings
        .map(ms => {
          const team = teams?.find(t => ms.project?.groupId === t.team.id)
          const channel =
            team?.channels?.find(c => ms.project?.channelId === c.id)
              ?.displayName || ""
          if (!team?.team.displayName || !channel) return null
          return new TeamMeetingStructure(ms, team.team.displayName, channel)
        })
        .filter(ms => ms !== null) as Array<TeamMeetingStructure>
    })

    return {
      state,
      selected,
      headers,
    }
  },
})
